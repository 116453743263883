<template>
    <div>
      <!-- <div class="d-flex justify-content-start align-items-center my-10">
        Toplam {{ totalItems }} müşteri bulundu.
      </div> -->
      <div
        class="d-flex align-items-center justify-content-between search-list-area"
      >
      <div class="d-flex align-items-center search-input mb-4">
          <input placeholder="Ara.." v-model="search" class="form-control mr-2" />
          <select v-model="list_length" class="form-control ml-2">
            <option value="" selected>Seçiniz</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
      </div>
  
      <div class="custom-list">
        <List
          :header="header"
          :data="items"
          :pagination="pagination"
          :loading="loading"
          @updatePage="updatePage"
          moduleName="Sistem Kullanıcı Listesi"
          :moduleCreateEvent="true"
          :moduleSearch="search"
        >
        <template  v-slot:item.id="{ item }">
        <strong>{{ item.id }}</strong>
      </template>
        </List>
        <!-- Create and Actions Popups -->
      </div>
      
    </div>
  </template>
  
  <style lang="scss" scoped>
  @media (max-width: 990px) {
    .search-list-area {
      flex-direction: column;
      .search-input {
        width: 100%;
        * {
          width: 100%;
        }
      }
    }
    .search-buttons {
      flex-direction: column;
    }
  }
  
  .badges-area {
    display: flex;
    gap: 1rem;
    p {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: $dveb-primary !important;
    }
    .badges {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      .badge {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        padding:0 !important;
  
        &.green {
          background-color: #44b6ae;
        }
        &.yellow {
          background-color: #ffac00;
        }
        &.red {
          background-color: red;
        }
      }
    }
  }
  .tapu-text{
      span{
          background-color: $dveb-divider;
          padding: .2rem .5rem;
          border-radius: .3rem;
      }
  }
  </style>
  
  <script>
  export default {
    name: "customerList",
    components: {
      Steps: () => import("@/components/global/StepBar"),
    },
    data() {
      return {
        search: "",
        filters: "",
        showInsertUpdate: false,
        showDetail: false,
        showImportModal: false,
        resetForm: false,
        confirmCreate: false,
        loading: false,
        header: [
          { text: "#", value: "id" ,size:"60px"},
          { text: "İşlem Adı", value: "islemAdi"},
          { text: "İşlem Tarihi", value: "islemTarihi" ,size:"210px" },
     
        ],
        items: [],
        pagination: {},
        meta: {},
        listSelectedItemIds: [],
        selectedItem: "",
        deleteOperationUrl: "",
        deleteIds: "",
        list_length:5,
      };
    },
  
    created() {
      this.getList();
    },
    methods: {
      getList() {
        this.loading = true;
        this.items = [
          {
            id: 1,
            islemAdi: "İşlem Adı 1",
            islemTarihi: "24.07.2023",
          },
          {
            id: 2,
            islemAdi: "İşlem Adı 2",
            islemTarihi: "25.07.2023",
          },
          {
            id: 3,
            islemAdi: "İşlem Adı 3",
            islemTarihi: "26.07.2023",
          },
       
        ];
        this.totalItems = 3;
  
        this.pagination = {
          current_page: 1,
          total_pages: Math.ceil(this.items?.length/this.list_length),
          total_items: this.items.length,
          list_length: this.list_length
        };
        this.loading = false;
        if (this.search) {
          const searchValue = this.search;
          const foundItems = this.items.filter((item) => {
            for (const prop in item) {
              if (item[prop].toString().toLowerCase().includes(searchValue)) {
                return true;
              }
            }
            return false;
          });
          this.items = foundItems;
        }
  
        // this.items = [];
        // this.totalItems = 0;
  
        // let queryLimit = this.perPage ? this.perPage : 10;
        // let query = `user/list?Page=${
        //   this.page ? this.page : 1
        // }&limit=${queryLimit}`;
        // if (this.search) {
        //   query += `&search=${this.search}`;
        // }
  
        // this.$dbFunctions
        //   .get(query)
        //   .then((res) => {
        //     if (res.success == true && res.count > 0) {
        //       this.items = res.data;
        //       this.totalItems = res.count;
  
        //       this.pagination = {
        //         current_page: res.page,
        //         total_pages: res.pageCount,
        //         total_items: res.count,
        //       };
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   })
        //   .finally(() => {
        //     this.loading = false;
        //   });
      },
      updatePage(value) {
        this.meta = value;
  
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
    },
    watch: {
      meta: {
        handler() {
          this.getList();
        },
        deep: true,
      },
      search: function (params) {
        this.getList();
      },
      list_length: function (params) {
        this.getList();
      },
    },
  };
  </script>
  